import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle1')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara1')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle2')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara2')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle3')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara3')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle4')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara4')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle5')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara5')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle6')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara6')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle7')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara7')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle8')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara8')}</p>
          <h2 className="text-blue">{t('WhatIsQualitySystemArticleTitle9')}</h2>
          <p className="pb-4">{t('WhatIsQualitySystemArticlePara9')}</p>
          <h2 className="text-blue">
            {t('WhatIsQualitySystemArticleTitle10')}
          </h2>
          <p>{t('WhatIsQualitySystemArticlePara10')}</p>
        </Col>
      </Row>
    </Container>
  );
};
